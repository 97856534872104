import React, { useCallback, useMemo } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'
import FrameCallbacks from '../../callbacks'

const withContainer = Component => props => {
  const { openNotification } = hooks.useNotification()
  const setFormValues = hooks.useFormChange()
  const identity = hooks.useIdentity()
  const billing_status = hooks.useBillingStatusList()
  const [{ record_id, isDurationRideMode, displayDownloadBillingButton, psp }] = hooks.useFormState()
  const [
    load_type,
    handling,
    base,
    driver_package_fee,
    prepaid,
    fees,
    duration_amount,
    option_amount,
    effective_handling_duration,
  ] = hooks.useFieldValues(['load_type', 'handling', 'base', 'driver_package_fee', 'prepaid', 'fees', 'duration_amount', 'option_amount', 'billing_status', 'assigned_transport.info.effective_handling_duration'])

  const [order] = hooks.useModel('order', [record_id], { single: true, populate: ['specific_parameter', 'quotation'] })
  const [downloadInvoice] = hooks.useOrderInvoice(record_id)

  const handleInvoiceDownload = useCallback(FrameCallbacks.handleInvoiceDownloadHanlder(downloadInvoice, openNotification), [downloadInvoice, openNotification])

  const included_waiting_minute = _.get(order, 'specific_parameter.included_waiting_minute') || 0
  const handling_amount = hooks.useOrderHandlingAmount(record_id, effective_handling_duration)

  const handling_slice = _.get(order, 'quotation.rate_parameter.handling_slice') || 0

  const total = useMemo(Callbacks.getTotalHandler(handling, base, driver_package_fee, fees, prepaid, duration_amount, option_amount), [handling, base, driver_package_fee, fees, prepaid, duration_amount, option_amount])
  const updateBilledHandlingDuration = useCallback(
    Callbacks.updateBilledHandlingDurationHandler(setFormValues, handling_amount, handling_slice)
    , [setFormValues, handling_amount, handling_slice])


  const displayDriverPackageFee = identity !== 'HP'
  const stripeLink = !!psp ? `https://dashboard.stripe.com/payments/${psp}` : null

  const mergedProps = {
    billing_status,
    total,
    included_waiting_minute,
    load_type,
    isDurationRideMode,
    displayDriverPackageFee,
    displayDownloadBillingButton,
    stripeLink,
    updateBilledHandlingDuration,
    handleInvoiceDownload
  }

  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
